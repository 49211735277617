import { render, staticRenderFns } from "./Cryptocurrency.vue?vue&type=template&id=7d8dc1f8&scoped=true"
import script from "./Cryptocurrency.vue?vue&type=script&lang=js"
export * from "./Cryptocurrency.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d8dc1f8",
  null
  
)

export default component.exports